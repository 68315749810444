import Button from '../UI/Button/Button';
import { routes } from '../../content/routes';
import { hrefs } from '../../content/data/aboutdata';
import './MobileMenu.css';

export default function MobileMenu ({ navigate, active = false, channel }) {
    return (
        <div className={`mobile-menu --xsm ${active ? "--active" : "--inactive"}`}>
            <nav className="flex fcol">
                {routes.map((item, i) => item.channel === channel &&
                    <div key={"mobile_menu_item_" + i.toString()} className="mobile-nav-item-container flex fend">
                        <Button title={item.name} type="text as-h1" onClick={e => navigate(i)}>{item.name}</Button>
                    </div>    
                )}
                {channel === 1 &&
                    <div className="mobile-nav-item-container flex fend">
                        <Button title="Deviant Art" type="text as-h1" onClick={() => window.location.href = hrefs.deviantArt}>Deviant Art</Button>
                    </div>
                }
            </nav>
        </div>
    );
};