import './Button.css';

const InnerButton = ({ content, type }) => {
    return typeof content === "string" && (type === "primary" || type === "secondary") ? (
        <>
            <span className="inner-text-original">{content}</span>
            <span className="inner-text-single-letters flex">
                {content.split("").map((char, i) =>
                    <span key={"button_char_" + i.toString()} className={char === " " ? "--space" : ""}>{char}</span>
                )}
            </span>
        </>
    ) : content;
};

export default function Button ({ onClick = () => null, type = "primary", active = false, title = "", children }) {
    const handleClick = e => {
        e.preventDefault();
        onClick(e);
    };
    return (
        <button title={title} className={`${type} ${active ? "--selected" : ""}`} onClick={handleClick} aria-label={title}>
            <InnerButton content={children} type={type}/>
        </button>
    );
};