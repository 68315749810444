import './Error.css';

export default function Error () {
    return (
        <div className="error-container block">
            <div>
                <h1>404</h1>
                <p>The requested resource does not exist</p>
            </div>
        </div>
    );
};