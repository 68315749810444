
import Video from "../Video/Video";
import { Link } from "react-router-dom";
import ArticleWrapper from "../ArticleWrapper/ArticleWrapper";

import "./Card.css";

export default function Card ({ content }) {
    return (
        <ArticleWrapper className="card-container">
            <div className="card-body flex fcol">
                {content.src &&
                    <div className="card-image">
                        <Video src={content.src}/>
                    </div>
                }
                <div className="card-main flex fcol fspbw">
                    <div className="card-content">
                        <div className="card-header">
                            <h3 className="text-fade">{content.title}</h3>
                        </div>
                        <div className="card-text">
                            {content.text}
                        </div>
                    </div>
                    <div className="card-footer flex fvcent fspbw">
                        <div>
                            <p className="subtitle">{content.subtitle}</p>
                        </div>
                        {content.action &&
                            <div>
                                <Link to={content.action} onClick={e => window.scrollTo(0, 0)}>more</Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ArticleWrapper>
    );
};