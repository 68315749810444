import GalleryItem from './GalleryItem/GalleryItem';

import './Gallery.css';

export default function Gallery ({ content, title, className, navigate = () => null }) {

    return (
        <div className="gallery-container block-grid block">
            {title &&
                <div className="gallery-header">
                    <h2 className="subtitle">{title}</h2>
                </div>
            }
            {content.map((item, i) => <GalleryItem key={"gallery_item_" + i.toString()} i={i} content={item} className={className} expandable={!!item.expandable} navigate={navigate}/>)}
        </div>
    );
};