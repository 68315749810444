import List from '../../components/UI/List/List';
import { hrefs } from './aboutdata';

/***
 * Card properties (optional) [comment]
 *  title: string
 *  descr: React fragment[ArticleWrapper styles, classNames & components apply]
 *  dir: array { name: string, (href: string) }
 *  url: webm
 *  action: array { href: string[if internal is set to true, relative path], type: string[icon], title: string, (internal: boolean) }
 *  expandable: boolean
***/

export const projects = [
    {
        title: "Photorealistic Neural Appearance Transfer - ETHZ Master thesis at NVIDIA (2021)",
        descr: <>
            <p>Proof of concept work that showed it was possible to transfer the appearance of an object to a <b><a href="https://www.matthewtancik.com/nerf" title="Neural Radiance Fields">NeRF</a></b> scene through 2D images only and retain parametric control.</p>
            <List caption="The contributions are:">
                <li>An appearance transfer pipeline, that captures the desired look and retains parametric properties, like lighting.</li>
                <li>A depth oracle network that can learn depth information without ground truth information and efficiently render NeRF scenes (down to 8 samples per ray instead of 64 samples).</li>
                <li>A method to combine a depth oracle and a NeRF network to preserve the shape of a NeRF scene during appearance transfer.</li>
            </List>
            <p>This was a joint project between ETHZ (<a href="https://cgl.ethz.ch/people/studentalumni.php" title="Computer Graphics Lab">CGL</a>) and NVIDIA.</p>
            <p>Thanks to <a href="http://granskog.xyz/" title="Jonathan Granskog">Jonathan Granskog</a> (NVIDIA), <a href="https://people.inf.ethz.ch/~mpapas/" title="Marios Papas">Marios Papas</a> (Disney Research), <a href="https://scholar.google.ch/citations?user=7iUaxXEAAAAJ&hl=en" title="Fabrice Rousselle">Fabrice Rousselle</a> (NVIDIA), <a href="https://jannovak.info/" title="Jan Novák">Jan Novák</a> (NVIDIA) &amp; <a href="https://indoorastronaut.ch/about-us/" title="Hendrik Baatz">Hendrik Baatz</a> (NVIDIA &amp; Indoor Astronaut) for helping and advising me through my thesis.</p>
        </>,
        dir: [
            {
                name: "Alexandre Cavaleri"
            }
        ],
        url: "/media/master_thesis.webm",
        expandable: true
    },
    {
        title: "Implementation of a fast CPU ray marcher based on sphere tracing (2020)",
        descr: <>
            <ul>
                <li>We avoid unnecessary computations and combine loop unrolling, code motion, lightweight data structures, and other techniques to make efficient use of the memory</li>
                <li>Approximations of high latency mathematical operations are introduced to lower computation bound SDFs</li>
                <li>Vectorization of the entire rendering pipeline allows for a massive increase in performance by computing eight rays at a time</li>
            </ul>
            <p>This project was done as part of the ETH Course - <a href="https://acl.inf.ethz.ch/teaching/fastcode/2021/" title="Advanced Systems Lab">Advanced Systems Lab</a>. It has now been proposed as a project idea for the course.</p>
        </>,
        dir: [
            {
                name: "Nihat Isik",
                href: "https://nihatisik.com/"
            },{
                name: "Qais El Okaili"
            },{
                name: "Alexandre Cavaleri"
            },{
                name: "David Graf"
            }
        ],
        url: "/media/raymarch2020.webm",
        action: [
            {
                href: "https://youtu.be/MrjcgPU0xp0",
                type: "Youtube",
                title: "Video"
            },{
                href: "https://github.com/devnio/Optimized-CPU-Ray-Marcher",
                type: "Github",
                title: "Github"
            }
        ],
        expandable: true
    },{
        title: "Shadow detection and removal in Aerial Geo Data (2020)",
        descr: <>
            <p>A very fast GPU tool(HLSL) was built to create the shadow detection dataset which allowed to finely select shadow areas in the HSV color space and paint away false positive areas with a brush.</p>
            <p>My approach leveraged a simple U-net architecture to detect shadows and then use this same network in a loss to remove shadows from Aerial images.</p>
            <p>A Pix2Pix based GAN approach was used to recreate patterns in the shadow areas while minimizing the amount of detected shadows areas.</p>
            <p>Thanks to <a href="https://www.lgdv.tf.fau.de/person/tobias-gunther/" title="Tobias Günther">Tobias Günther</a> for helping and advising me through this research project.</p>
        </>,
        dir: [
            {
                name: "Alexandre Cavaleri"
            },{
                name: "Jin Dingguang"
            }
        ],
        url: "/media/shadows2020.webm",
        expandable: true
    },{
        title: "PhysIce, a real-time SPH Fluid simulation engine",
        descr: <>
            <ul>
                <li>Simulates real time avalanches without any boundaries</li>
                <li>Runs at 60 fps on normal laptop with DirectX 12 compatible hardware</li>
                <li>Use of DirectX 12 and compute shaders to overlap simulation with the rendering</li>
            </ul>
            <p>Made as part of the course <a href="https://cgl.ethz.ch/teaching/simulation20/home.php" title="Master course">Physically based simulation</a> at ETH Zürich</p>
        </>,
        dir: [
            {
                name: "Rudolf Varga"
            },{
                name: "Costanza Maria Improta"
            },{
                name: "Alexandre Cavaleri"
            }
        ],
        url: "/media/physice2019.webm",
        action: [
            {
                href: "https://youtu.be/V-QTDy9iMQg",
                type: "Youtube",
                title: "Video"
            }
        ],
        expandable: true
    },{
        title: "Naturalize (2019)",
        descr: <>
            <p>Naturalize (2019) is a prototype game where the goal is to destroy villages with avalanches before global warming melts the glaciers away.</p>
            <p>The game engine was built from scratch (using MonoGame library) in 3 months as part of the Master course <a href="https://gtc.inf.ethz.ch/education/game-programming-laboratory.html" title="ETHZ game programming laboratory">Game Programming Laboratory</a> at ETH Zürich.</p>
        </>,
        dir: [
            {
                name: "Alexandre Cavaleri"
            },{
                name: "Mischa Brander"
            },{
                name: "Aaron Keller"
            },{
                name: "Kasim Taskin"
            }
        ],
        url: "/media/nat2019.webm",
        action: [
            {
                href: "https://youtu.be/hqmCokwnrxo",
                type: "Youtube",
                title: "Trailer"
            },{
                href: "https://www.youtube.com/watch?v=s24vUE7HaBY",
                type: "Youtube",
                title: "Presentation"
            }
        ],
        expandable: true
    }
];

export const art = [
    {
        title: "Movie snippets (2015-2022)",
        descr: <>
            <p>Various film works from 2015 to 2022. Some of these works are catalogued on the Aryonix Arts website. Aryonix Arts is the artistic section of the Aryonix project. It concerns film experiments, videos, special effects, data capture and general graphical designs that are sometimes related to Aryonix.</p>
        </>,
        dir: [
            {
                name: "Alexandre Cavaleri"
            }
        ],
        url: "/media/bmcc.webm",
        action: [
            {
                href: "/arts",
                type: "AryonixArts",
                title: "Aryonix Arts",
                internal: true
            }
        ],
        expandable: false
    },{
        title: "Art works (2013-2016)",
        descr: <>
            <p>Artistic works uploaded on <a href={hrefs.deviantArt} title="DeviantArt">DeviantArt</a>.</p>
        </>,
        dir: [
            {
                name: "Alexandre Cavaleri"
            }
        ],
        url: "/media/deviantart.webm",
        action: [
            {
                href: hrefs.deviantArt,
                type: "DeviantArt",
                title: "Works"
            }
        ],
        expandable: false
    },{
        title: "Showreel of previous works (2011-2013)",
        descr: <>
            <p>Directed "Eutrinom" a medium length action movie and a few extra smaller projects.</p>
        </>,
        dir: [
            {
                name: "Alexandre Cavaleri"
            }
        ],
        url: "/media/showreel.webm",
        action: [
            {
                href: "https://youtu.be/9dykPtYlzK8",
                type: "Youtube",
                title: "Video"
            }
        ],
        expandable: false
    }
];
