import './Loading.css';

export default function Loading () {
    return (
        <div className="loading-container block">
            <div>
                <h1>Loading...</h1>
                <p>You will be redirected shortly</p>
            </div>
        </div>
    );
};