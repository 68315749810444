export const hrefs = {
    deviantArt: "https://www.deviantart.com/cavaleum"
};

export const aboutData = {
    name: "Alexandre Cavaleri",
    funcs: [
        "Computer scientist",
        "Entrepreneur",
        "Artist"
    ],
    sum: "I focus on the intersection of Computer Graphics and Deep Learning, with an emphasis on real time products like video games and simulators. Currently working as a Research Engineer at the Computer Graphics Laboratory (ETHZ).",
    sumAlt: [
        "The mission of Aryonix is to bring real time photorealism to video games and simulations. We hope to achieve this ambitious goal by leveraging deep learning generative technologies.",
        "Aryonix Arts is the artistic section of the Aryonix project. It concerns film experiments, videos, special effects, data capture and general graphical designs that are sometimes related to Aryonix."
    ],
    education: [
        {
            type: "ethz",
            title: "MSc, Computer Science, ETH Zürich, Switzerland"
        },{
            type: "epfl",
            title: "BSc, Computer Science, EPFL, Switzerland"
        }
    ],
    links: [
        {
            type: "Email",
            href: "mailto:contact@aryonix.ch"
        },{
            type: "Youtube",
            href: "https://www.youtube.com/channel/UCboyaeUSTGVOi3EMnrN4TEg"
        }
    ],
    img: "/media/me.jpg",
    url: "Aryonix",
    location: ["Switzerland"]
};
