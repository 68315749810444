import { Link } from 'react-router-dom';
import { LogoText, LogoTextArts } from '../../content/logo/Logo';
import { aboutData, hrefs } from '../../content/data/aboutdata';
import { LocationIcon, EmailIcon } from '../UI/Icons/ContactIcons';
import { routes } from '../../content/routes';

import './Footer.css';

const channelInfo = [
    {
        logo: <LogoText className="footer-logo" height="48px"/>
    },{
        logo: <LogoTextArts className="footer-logo" height="48px"/>
    }
];

const ListItem = ({ icon, content = [], title = "", onClick = null }) => {
    const handleClick = e => {
        e.preventDefault();
        return onClick && onClick();
    };
    return (
        <div title={title} className="flex fvcent">
            <div className="list-item-icon flex">
                <div>{icon}</div>
            </div>
            <div className="list-item-content">
                {!onClick ?
                    content.map((item, i) => [i > 0 ? <br key={"list_item_content_footer_1_" + i.toString()}/> : null, <span key={"list_item_content_footer_0_" + i.toString()}>{item}</span>])
                    :
                    <a className="bw" href="/" onClick={handleClick} title={content}>{content}</a>
                }
            </div>
        </div>
    );
};

export default function Footer ({ navigate, channel }) {
    return (
        <footer className="App-footer">
            <div className="footer-top">
                <div className="footer-panel-1">
                    <div>
                        {channelInfo[channel].logo}
                    </div>
                    <nav className="flex fwrap">
                        {routes.map((item, i) => item.channel === channel &&
                            <span key={"footer_link_" + i.toString()}>
                                {item.separator && <span className="footer-nav-separator">|</span>}
                                <Link to={item.path} onClick={e => {e.preventDefault(); navigate(i);}}>{item.name}</Link>
                            </span>
                        )}
                        {channel === 1 &&
                            <span>
                                <span className="footer-nav-separator">|</span>
                                <a href={hrefs.deviantArt} title="Deviant Art">Deviant Art</a>
                            </span>}
                    </nav>
                </div>
                <div className="footer-panel-2">
                    <ListItem icon={<LocationIcon/>} title="Location" content={aboutData.location}/>
                    <ListItem icon={<EmailIcon/>} title="Email" content={aboutData.links[0].href.replace(/mailto:/g, "")} onClick={() => window.location.href = aboutData.links[0].href}/>
                </div>
                <div className="footer-panel-3">
                    <p>{aboutData.sumAlt[channel]}</p>
                </div>
            </div>
            <div className="footer-copyright-container flex fcol fvcent fgap-sm">
                {channel === 1 && <span className="footer-copyright-disclaimer">Youtube footage, stripped of any copyrighted audio and without visible faces, is free to copy as data, also for commercial purposes, as long as Aryonix Arts is cited at the end of the work.</span>}
                <span className="footer-copyright">{aboutData.url} © {new Date().getFullYear()}</span>
            </div>
        </footer>
    );
}
