import ArticleWrapper from '../UI/ArticleWrapper/ArticleWrapper';
import ArticleNavigation from '../UI/ArticleNavigation/ArticleNavigation';
import { articles } from '../../content/articles/articleIndex';
import { Suspense, useCallback, useEffect, useState, startTransition } from 'react';
import Pending from '../UI/Pending/Pending';
import { useNavigate, useLocation } from 'react-router-dom';

import './Articles.css';

const Unavailable = () => <div>
    <p>Resource does not exist or is unavailable</p>
</div>;

export default function Articles ({ title = "", channel = 0 }) {
    let navigate = useNavigate();
    let location = useLocation();
    const [currentSelected, setSelected] = useState(0);

    const nav = useCallback((page, idx) => navigate(`/${page}/${articles[channel][idx].url}`), [ navigate, channel ]);
    const getLoc = useCallback(() => location.pathname.split(/\//g).filter(item => item), [ location.pathname ]);

    const handleSelect = useCallback(n => {
        const p = getLoc();
        if(p[1] !== articles[channel][n].url) nav(p[0], n);
        if(n !== currentSelected) setSelected(n);
    }, [ getLoc, currentSelected, nav, channel ]);
    
    useEffect(() => {
        const p = getLoc();
        let newIdx = currentSelected;
        for(let i = 0; i < articles[channel].length; i++){
            if(articles[channel][i].url === p[1]){
                newIdx = i;
                break;
            }
        }
        startTransition(() => handleSelect(newIdx));
    }, [ getLoc, nav, handleSelect, channel, currentSelected ]);

    useEffect(() => {
        document.title = title + " | " + articles[channel][currentSelected].title;
    }, [ title, currentSelected, channel ]);

    try{
        const ArticleComponent = articles[channel][currentSelected].component;
        return (
            <div className="articles-container">
                <ArticleNavigation articles={articles[channel]} currentSelected={currentSelected} onSelected={handleSelect}/>
                <ArticleWrapper>
                    <Suspense fallback={<Pending/>}>
                        <ArticleComponent/>
                    </Suspense>
                </ArticleWrapper>
            </div>
        );
    }catch(err){
        return <Unavailable/>;
    }
};