
/***
 * landingSections: array of sections
 * 
 * section:
 * title: string OR null (disables title)
 * type: "cards" OR "text"
 * cards: array
 * 
 * card:
 *  title: card title
 *  subtitle: card subtitle/section
 *  text: card text
 *  src: webm src or null
 *  action: null (no action) OR string (relative path/to/page), only internal links, eg: /articles/differentiable%20rendering
 * 
 * ALL STYLES FROM ARTICLE WRAPPER APPLY | DO NOT USE className="subtitle"
***/

export const landingSections = [
    {
        title: "First proof of concept - 2021",
        type: "cards",
        cards: [
            {
                title: "Unconstrained real photos",
                subtitle: "research",
                text: (
                    <>
                        <p>Appearance transfer based on real photos. Our model struggles to retain parametric control.</p>
                    </>
                ),
                src: "/media/real_transfer.webm",
                action: null
            },{
                title: "Depth oracle",
                subtitle: "research",
                text: (
                    <>
                        <p>Our depth oracle speeds up rendering and retains shape during appearance transfer.</p>
                    </>
                ),
                src: "/media/pipeline_transfer.webm",
                action: null
            },{
                title: "Constrained real photos",
                subtitle: "research",
                text: (
                    <>
                        <p>We can transfer the appearance and retain the parametric control when the real photos are closer to the base scene.</p>
                    </>
                ),
                src: "/media/synth_transfer.webm",
                action: null
            }
        ]
    },{
        title: null,
        type: "text",
        text: (
            <>
                <h2>Features</h2>
                <p>The contributions of this stage are threefold.</p>
                <ul>
                    <li>A low-res appearance transfer pipeline that retains parametric control</li>
                    <li>A depth oracle network to efficiently render NeRF scenes</li>
                    <li>Shape preservation during appearance transfer</li>
                </ul>
            </>
        )
    }
];
