import { useState, useEffect } from 'react';
import { useWindowSize } from '../../util/useWindowSize';
import { MoreIcon, LessIcon } from '../Icons/ExpandIcons';

import './ArticleNavigation.css';

export default function ArticleNavigation ({ articles, currentSelected, onSelected }) {
    const [isExpanded, setExpanded] = useState(false);
    const [mobileTabs, setMobileTabs] = useState(false);
    let clientX = useWindowSize();

    const handleClick = (e, n) => {
        e.preventDefault();
        onSelected(n);
        setExpanded(false);
    };

    useEffect(() => {
        setMobileTabs(clientX < 900);
    }, [clientX]);

    return (
        <div className="article-navigation-container">
            <ul className={`xsm-tabs ${isExpanded ? "--active" : ""}`} role="menu">
                {mobileTabs &&
                    <li title={articles[currentSelected].title} role="button" aria-haspopup="menu" tabIndex="0" className="--xmd flex fvcent fspbw clickable --selected visible-nav-item" onClick={() => setExpanded(!isExpanded)} aria-label="Select an article">
                        <span>{articles[currentSelected].title}</span>
                        <span>
                            {isExpanded ? <LessIcon/> : <MoreIcon/>}
                        </span>
                    </li>
                }
                {(!mobileTabs || (mobileTabs && isExpanded)) && articles.map((item, i) =>
                    <li title={item.title} role="menuitem" tabIndex="0" key={"article_navigation_" + i.toString()} className={`flex fcol clickable ${currentSelected === i ? "--selected" : ""} ${mobileTabs && isExpanded ? "--expanded" : "--hidden"}`} onClick={e => handleClick(e, i)}>
                        <span className="main"><strong>{item.title}</strong></span>
                        <span className="sub">{item.date}</span>
                    </li>
                )}
            </ul>
        </div>
    );
};