import { Article } from './Article';

/***
* Add latest article on top
* new Article(title = "string", date = "string", filename = "string")
*   title: short title (does not need to be the same as the original title), keep it short, avoid long words
*   date: date
*   filename: file name in /articles folder
***/

const mainArticles = [
    new Article("Differentiable rendering", "April 15, 2022", "ArticleDiffRender", 0)
];
const artsArticles = [
    new Article("Downhill Wings logo design", "October 2, 2022", "ArticleLogoDHWings", 1),
    new Article("TutorLatin logo design", "August 28, 2022", "ArticleLogoTutorLatin", 1),
    new Article("Aryonix logo", "June 1, 2022", "ArticleLogo", 1)
];

export const articles = [
    mainArticles,
    artsArticles
];