import { LogoIconArts } from '../../../content/logo/Logo';
import './icons.css';

export const getIcon = name => {
    switch(name){
        case "Email": return <EmailIcon/>;
        case "Youtube": return <YoutubeIcon/>;
        case "Github": return <GithubIcon/>;
        case "DeviantArt": return <DeviantArtLogo/>;
        case "AryonixArts": return <LogoIconArts height="24"/>
        default: return <EmailIcon/>;
    };
};

export const EmailIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48" fill="currentColor">
    <path d="M7 40Q5.8 40 4.9 39.1Q4 38.2 4 37V11Q4 9.8 4.9 8.9Q5.8 8 7 8H41Q42.2 8 43.1 8.9Q44 9.8 44 11V37Q44 38.2 43.1 39.1Q42.2 40 41 40ZM24 24.9 41 13.75V11L24 21.9L7 11V13.75Z"/>
</svg>;

export const YoutubeIcon = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="24" width="24" viewBox="0 0 461.001 461.001">
    <g>
        <path fill="currentColor"  d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
            c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
            C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
            c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"/>
    </g>
</svg>;

export const LocationIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48">
    <path fill="currentColor" d="M24 29.5Q26.55 29.5 28.625 28.3Q30.7 27.1 31.95 25.1Q30.25 23.8 28.25 23.15Q26.25 22.5 24 22.5Q21.75 22.5 19.75 23.15Q17.75 23.8 16.05 25.1Q17.3 27.1 19.375 28.3Q21.45 29.5 24 29.5ZM24 19.5Q25.45 19.5 26.475 18.475Q27.5 17.45 27.5 16Q27.5 14.55 26.475 13.525Q25.45 12.5 24 12.5Q22.55 12.5 21.525 13.525Q20.5 14.55 20.5 16Q20.5 17.45 21.525 18.475Q22.55 19.5 24 19.5ZM24 40.05Q30.65 34 33.825 29.075Q37 24.15 37 20.4Q37 14.5 33.225 10.75Q29.45 7 24 7Q18.55 7 14.775 10.75Q11 14.5 11 20.4Q11 24.15 14.25 29.075Q17.5 34 24 40.05ZM24 44Q15.95 37.15 11.975 31.275Q8 25.4 8 20.4Q8 12.9 12.825 8.45Q17.65 4 24 4Q30.35 4 35.175 8.45Q40 12.9 40 20.4Q40 25.4 36.025 31.275Q32.05 37.15 24 44ZM24 20.4Q24 20.4 24 20.4Q24 20.4 24 20.4Q24 20.4 24 20.4Q24 20.4 24 20.4Q24 20.4 24 20.4Q24 20.4 24 20.4Q24 20.4 24 20.4Q24 20.4 24 20.4Z"/>
</svg>;

export const GithubIcon = () => <svg width="24" height="24" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" transform="scale(64)"/>
</svg>;

export const DeviantArtLogo = () => <svg viewBox="0 0 100 166.61" xmlns="http://www.w3.org/2000/svg" width="14" height="24" fill="currentColor"><path d="M100 0H71.32l-3.06 3.04-14.59 27.85-4.26 2.46H0v41.62h26.4l2.75 2.75L0 133.36v33.25l28.7-.01 3.07-3.05 14.62-27.86 4.17-2.41H100v-41.6H73.52L70.84 89 100 33.33"/></svg>;