import { useState, useEffect } from 'react';
import Button from '../UI/Button/Button';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '../UI/Icons/MenuIcon';
import { LogoText, LogoIcon, LogoIconArts, LogoTextArts } from '../../content/logo/Logo';
import { routes } from '../../content/routes';
import { hrefs } from '../../content/data/aboutdata';

import './Header.css';

const channelInfo = [
    {
        homeIdx: 0,
        logo: <>
            <LogoIcon height="48px"/>
            <LogoText height="32px" className="--md"/>
        </>
    },{
        homeIdx: 3,
        logo: <>
            <LogoIconArts height="48px"/>
            <LogoTextArts height="32px" className="--md"/>
        </>
    }
];

export default function Header ({ navigate, isMenuOpen, setMenu, channel }) {
    let location = useLocation();
    const [currentRoute, setRoute] = useState(routes[0]);

    useEffect(() => {
        const p = location.pathname.split(/\//);
        setRoute(`${p[0] ?? ""}/${p[1] ?? ""}`);
    }, [ location.pathname ]);

    return (
        <header className="App-header">
            <nav className="main-nav">
                <div className="App-logo flex fspbw fvcent">
                    <Link to={routes[channelInfo[channel].homeIdx].path} title={routes[channelInfo[channel].homeIdx].name} onClick={() => navigate(channelInfo[channel].homeIdx)}>
                        <div className="flex fvcent">
                            {channelInfo[channel].logo}
                        </div>
                    </Link>
                    <Button title="Menu" type="icon --xsm" onClick={setMenu}>
                        <MenuIcon active={isMenuOpen}/>
                    </Button>
                </div>
                <div className="App-nav --sm">
                    <div className="flex fend">
                        {routes.map((item, i) => item.channel === channel && <Button key={"nav_" + i.toString()} active={item.path === currentRoute} onClick={e => navigate(i)} type="secondary">{item.name}</Button>)}
                        {channel === 1 && <Button active={false} onClick={() => window.location.href = hrefs.deviantArt} type="secondary">Deviant Art</Button>}
                    </div>
                </div>
            </nav>
        </header>
    );
};