import Video from '../../UI/Video/Video';
import Button from '../../UI/Button/Button';
import { useState } from 'react';
import { MoreIcon, LessIcon } from '../../UI/Icons/ExpandIcons';
import ArticleWrapper from '../../UI/ArticleWrapper/ArticleWrapper';
import { getIcon } from "../../UI/Icons/ContactIcons";

import './GalleryItem.css';

const AttributionItem = ({content, isSecondLast = false, isLast = false}) => {
    return (
        <span className="attribution-item">
            {content.href ?
                <a href={content.href} title={content.name}>{content.name}</a>
                :
                content.name
            }
            {isLast ? "" : isSecondLast ? <span><span> </span>&<span> </span></span> : <span>, </span>}
        </span>
    );
};

export default function GalleryItem ({ content, className = "", expandable = false, navigate = () => null }) {
    const [isExpanded, setExpanded] = useState(false);

    const handleExpand = e => {
        e.preventDefault();
        e.stopPropagation();
        setExpanded(!isExpanded);
    };

    const handleClick = item => {
        if(item.internal === true) return navigate(item.href);
        return window.location.href = item.href;
    };

    return (
        <div className={`item-container ${className} ${expandable ? "--expandable" : ""} ${isExpanded ? "--active" : "--inactive"}`}>
            <div className="video-container flex fcent">
                <Video src={content.url}/>
            </div>
            <div className="description-container">
                <ArticleWrapper>
                    <h3 className="description-header">{content.title}</h3>
                    {Array.isArray(content.dir) &&
                        <p className="attr text-fade">
                            {content.dir.map((item, i) => <AttributionItem key={"attribution_" + i.toString()} content={item} isSecondLast={i === content.dir.length - 2} isLast={i === content.dir.length - 1}/>)}
                        </p>
                    }
                    {content.descr}
                </ArticleWrapper>
                {expandable && !isExpanded && <div className="expandable-overlay" onClick={() => setExpanded(true)}></div>}
                <div className="description-footer flex fvend fspbw">
                    <div>
                        {expandable &&
                            <div className="expand-action">
                                <a href="/" title={!isExpanded ? "Show more" : "Close"} className="flex fvcent" onClick={handleExpand}>
                                    <span className="--sm">{!isExpanded ? "Show more" : "Close"}</span>
                                    {isExpanded ? <LessIcon/> : <MoreIcon/>}
                                </a>
                            </div> 
                        }
                    </div>
                    <div className="description-footer-action flex fvcent fwrap">
                        {content.action && content.action.map((item, i) =>
                            <Button key={"description_footer_actions_" + i.toString()} onClick={() => handleClick(item)} type="text" title={item.title}>
                                <span className="description-button flex fvcent">
                                    {getIcon(item.type)}
                                    <span className="footer-action-label">{item.title}</span>
                                </span>
                            </Button>
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};