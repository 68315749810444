import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Landing from './components/Landing/Landing';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useRef, useState, useEffect, lazy, Suspense } from 'react';
import Articles from './components/Articles/Articles';
import About from './components/About/About';
import { routes } from './content/routes';
import Error from './components/Error/Error';
import MobileMenu from './components/MobileMenu/MobileMenu';
import Loading from './components/Loading/Loading';

const ArtsPortfolio = lazy(() => import('./components/ArtsPortfolio/ArtsPortofolio'));

const channelThemes = [
  "--dark-theme",
  "--art-theme"
];

export default function App() {
  const [isMenuOpen, setMenu] = useState(false);
  let navigate = useNavigate();
  const workRef = useRef(null);
  let location = useLocation();
  const [channel, setChannel] = useState(0);

  const handleNavRedirect = (navIdx = 0, anchor = null) => {
    if(isMenuOpen) setMenu(false);
    navigate(typeof navIdx === 'number' ? routes[navIdx].path : navIdx);
    if(!anchor) window.scrollTo(0, 0);
    else if(anchor === "work") workRef.current.scrollIntoView();
  };

  useEffect(() => {
    const p = "/" + (location.pathname.split(/\//g).filter(item => item?.trim())[0] || "");
    for(let i = 0; i < routes.length; i++){
      if(routes[i].path === p && routes[i].channel !== channel){
        setChannel(routes[i].channel);
        break;
      }
    }
  }, [ location.pathname, channel ]);

  useEffect(() => {
    document.documentElement.className = channelThemes[channel];
  }, [ channel ]);

  return (
    <div className={`App ${isMenuOpen ? "--menu-active" : ""}`}>
      <Header
        navigate={handleNavRedirect}
        isMenuOpen={isMenuOpen}
        setMenu={() => setMenu(!isMenuOpen)}
        channel={channel}
      />
      <MobileMenu
        navigate={handleNavRedirect}
        active={isMenuOpen}
        channel={channel}
      />
        <main className="App-main">
          <Routes>
            <Route path={routes[0].path} element={
              <Landing
                navigate={handleNavRedirect}
                workRef={workRef}
                title={routes[0].title}
              />
            }/>
            <Route path={routes[1].path + "//*"} element={
                <Articles
                  title={routes[1].title}
                  channel={channel}
                />
              }
            />
            <Route path={routes[2].path} element={
              <About
                title={routes[2].title}
                navigate={handleNavRedirect}
              />
            }/>
            <Route path={routes[3].path} element={
              <Suspense fallback={<Loading/>}>
                <ArtsPortfolio title={routes[3].title}/>
              </Suspense>
            }/>
            <Route path={routes[4].path + "//*"} element={
              <Articles
                title={routes[4].title}
                channel={channel}
              />
            }/>
            <Route path="*" element={<Error/>}/>
          </Routes>
        </main>
      <Footer
        navigate={handleNavRedirect}
        channel={channel}
      />
    </div>
  );
};
