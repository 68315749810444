export const routes = [
    {
      path: "/",
      name: "Home",
      title: "Aryonix | A new approach to reach photorealism",
      separator: false,
      channel: 0
    },{
      path: "/articles",
      name: "Articles",
      title: "Aryonix | Articles",
      separator: true,
      channel: 0
    },{
      path: "/about",
      name: "About me",
      title: "Alexandre Cavaleri | About me",
      separator: true,
      channel: 0
    },{
      path: "/arts",
      name: "Works",
      title: "Aryonix Arts | Works",
      separator: false,
      channel: 1
    },{
      path: "/arts_articles",
      name: "Articles",
      title: "Aryonix Arts | Articles",
      separator: true,
      channel: 1
    }
];