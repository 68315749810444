import { useEffect } from 'react';
import Button from '../UI/Button/Button';
import Video from '../UI/Video/Video';
import LandingBlock from './LandingBlock/LandingBlock';
import { landingSections } from '../../content/data/landingdata';

import './Landing.css';

export default function Landing ({ navigate, workRef, title }) {

    useEffect(() => {
        document.title = title;
    }, [ title ]);

    return (
        <div className="landing-container block-grid">
            <div className="block hero-container">
                <div className="hero-title">
                    <h1>A new approach to reach photorealism</h1>
                    <p className="--sm">We leverage differentiable rendering to reach photorealism by fine tuning 3D dynamic scenes to look like a set of target real life photos.</p>
                    <div className="hero-actions flex">
                        <Button onClick={() => navigate(0, "work")} type="primary">Work</Button>
                        <Button onClick={() => navigate(2)} type="secondary">About me</Button>
                    </div>
                </div>
                <div className="hero-image flex fcent">
                    <Video src="/media/hero.webm" forcePlay={true}/>
                </div>
            </div>
            {landingSections.map((section, i) => <LandingBlock key={"landing_section_" + i.toString()} content={section} innerRef={i === 0 ? workRef : null}/>)}
        </div>
    );
};