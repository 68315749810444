import { lazy } from "react";

const channelPaths = [
    "articlesMain",
    "articlesArts"
];

export class Article {
    constructor(title = "", date = "", filename = "", channel = 0){
        this.title = title;
        this.date = date;
        this.component = !!filename ? lazy(() => import(`./${channelPaths[channel]}/${filename}`)) : null;
        this.url = encodeURIComponent(title.toLowerCase());
        this.channel = channel;
    }
};