import { useEffect } from 'react';
import { aboutData } from '../../content/data/aboutdata';
import { projects, art } from '../../content/data/projectlist';
import HeroSection from './HeroSection/HeroSection';
import Gallery from '../Gallery/Gallery';

export default function About ({ title, navigate }) {
    
    useEffect(() => {
        document.title = title;
    }, [ title ]);

    return (
        <div className="about-container block-grid">
            <HeroSection content={aboutData}/>
            <Gallery content={projects} title="Projects"/>
            <Gallery content={art} title="Art" navigate={navigate}/>
        </div>
    );
};