import useIntersection from '../../util/useIntersection';
import { useWindowSize } from '../../util/useWindowSize';
import { useEffect, useRef, useState } from 'react';

import './Video.css';

const generateFallback = url => url.replace(/\.webm/gi, ".mp4");

export default function Video ({ src, forcePlay = false }) {
    const vidRef = useRef(null);
    const isIntersecting = useIntersection(vidRef, 1);
    const [fallback] = useState(generateFallback(src));

    let windowX = useWindowSize();
    const [isHovered, setHovered] = useState(false);

    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        try{
            if(isLoaded && isIntersecting && (isHovered || windowX < 600 || forcePlay) && vidRef.current.paused && (vidRef.current.readyState || forcePlay) && vidRef.current.HAVE_CURRENT_DATA) vidRef.current.play();
            else if(!vidRef.current.paused) vidRef.current.pause();
        }catch(err){}
    }, [isLoaded, isIntersecting, isHovered, windowX, forcePlay]);

    return (
        <video
            className="video-handler"
            loop
            controls=""
            controlsList="nodownload"
            muted
            webkit-playsinline="true"
            playsInline
            ref={vidRef}
            onMouseEnter={() => !forcePlay && setHovered(true)}
            onMouseLeave={() => !forcePlay && setHovered(false)}
            onLoadedMetadata={() => setLoaded(true)}
        >
            <source src={src} type="video/webm"/>
            <source src={fallback} type="video/mp4"/>
        </video>
    );
};