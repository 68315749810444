import './icons.css';

export default function MenuIcon ({ active = false }) {
    return (
        <div className={`menu-icon ${active ? "--active" : "--inactive"}`}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};