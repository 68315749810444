import Card from "../../UI/Card/Card";
import ArticleWrapper from "../../UI/ArticleWrapper/ArticleWrapper";

import "./LandingBlock.css";
import "../../Gallery/Gallery.css";

export default function LandingBlock ({ content, innerRef = null }) {
    return (
        <div className="landing-block-container gallery-container block-grid block" ref={innerRef}>
            {content.title &&
                <div className="gallery-header">
                    <h2 className="subtitle">{content.title}</h2>
                </div>
            }
            {content.type === "cards" ?
                <div className="landing-card-container">
                    {content.cards && content.cards.map((item, i) => <Card key={"landing_card_" + i.toString()} content={item}/>)}
                </div>
                :
                <div className="landing-text-container">
                    {content.text && <ArticleWrapper className="custom-text-container">{content.text}</ArticleWrapper>}
                </div>
            }
        </div>
    );
};