import { getIcon } from '../../UI/Icons/ContactIcons';
import { getExternalIcons } from '../../UI/Icons/ExternalIcons';
import './HeroSection.css';

export default function HeroSection ({ content }) {
    return (
        <div className="hero-section-container block">
            <div className="hero-section-body about-grid">
                <div className="hero-section-header">
                    <p className="subtitle">Team</p>
                    <h2 className="as-h1">{content.name}</h2>
                </div>
                <div className="hero-section-content">
                    <ul>
                        {content.funcs.map((item, i) => <li key={"content_item_" + i.toString()}>{item}</li>)}
                    </ul>
                    <ul>
                        <li>{content.sum}</li>
                    </ul>
                </div>
                <div className="hero-section-secondary-content">
                    <ul className="education-container">
                        {content.education.map((item, i) =>
                            <li key={"education_item_" + i.toString()}>
                                <span>
                                    {getExternalIcons(item.type)}
                                </span>
                                <span>
                                    {item.title}
                                </span>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="hero-section-footer">
                    <ul>
                        {content.links.map((item, i) => <li key={"footer_item_" + i.toString()}><a href={item.href} title={item.type}>{getIcon(item.type)}</a></li>)}
                    </ul>
                </div>
            </div>
            <div className="hero-section-image">
                <img src={content.img} alt={content.name}/>
            </div>
        </div>
    );
};